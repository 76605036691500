<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row align="center" justify="start" class="mb-3">
          <v-col cols="12" md="3">
            <v-text-field type="text" filled label="Код" v-model="code"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field type="text" filled label="Найменування" v-model="short_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="short_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="4" style="display: flex; height: 78px">
            <div style="display: flex; padding-left: 8px; min-width: 180px; margin-right: 21px">
              <v-btn depressed small color="success" height="auto"
                     @click.stop="changeYear(-1)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <div
                  style="flex: 1; text-align: center; height: auto;
                 background-color: white; font-weight: 500;
                 font-size: 1.1rem; cursor: no-drop;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 line-height: 2rem;"
              >{{ this.year }}
              </div>
              <v-btn depressed small color="success" height="auto"
                     @click.stop="changeYear(1)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
            <v-btn style="flex: 1" @click.stop="fill_settings = true" height="auto">Заповнити графік</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-dialog v-model="fill_settings" persistent max-width="700">
        <v-card>
          <v-card-text class="py-2 px-4">
            <v-checkbox class="mb-2" v-model="localOptions.fill_from_template"
                        color="grey darken-2"
                        hide-details
                        label="Заповнити із шаблону"
            />
            <v-divider></v-divider>
            <div v-if="localOptions.fill_from_template" class="mt-2">
              <v-list
                  dense
                  nav
                  color="grey lighten-4"
              >
                <v-list-item-group
                    v-model="localOptions.template"
                    mandatory
                >
                  <v-list-item
                      v-for="(template, idx_template) in fillTemplates"
                      :key="`template-${idx_template}`"
                      @click.stop
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                            :input-value="active"
                            color="success"
                            hide-details
                        />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ template.text }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
            <div class="mt-3 d-flex" v-if="localOptions.fill_from_template">
              <v-checkbox v-model="localOptions.evening_days"
                          color="grey darken-2"
                          hide-details
                          label="Вечірні години"
                          class="mr-4"
              />
              <v-checkbox v-model="localOptions.night_days"
                          color="grey darken-2"
                          hide-details
                          label="Нічні години"
              />
            </div>
            <div class="mt-3 d-flex flex-wrap" v-if="localOptions.fill_from_template">
              <v-col cols="12" sm="6" md="4"
                     v-if="(localOptions.template || 0) > 1 || localOptions.by_change"
              >
                <v-text-field
                    v-model.number="localOptions.hours_per_day"
                    hide-details
                    color="grey"
                    label="Годин в день"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                    ref="menu_work_start"
                    v-model="localOptions.menu_work_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="localOptions.general_times.work_start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localOptions.general_times.work_start"
                        label="Початок роб.годин"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="localOptions.menu_work_start"
                      v-model="localOptions.general_times.work_start"
                      full-width
                      @click:minute="$refs.menu_work_start.save(localOptions.general_times.work_start)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="localOptions.evening_days">
                <v-menu
                    ref="menu_evening_start"
                    v-model="localOptions.menu_evening_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="localOptions.general_times.evening_start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localOptions.general_times.evening_start"
                        label="Початок веч.годин"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="localOptions.menu_evening_start"
                      v-model="localOptions.general_times.evening_start"
                      full-width
                      @click:minute="$refs.menu_evening_start.save(localOptions.general_times.evening_start)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="localOptions.night_days">
                <v-menu
                    ref="menu_night_start"
                    v-model="localOptions.menu_night_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="localOptions.general_times.night_start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localOptions.general_times.night_start"
                        label="Початок ніч.годин"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="localOptions.menu_night_start"
                      v-model="localOptions.general_times.night_start"
                      full-width
                      @click:minute="$refs.menu_night_start.save(localOptions.general_times.night_start)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </div>
            <div class="mt-3" v-if="!localOptions.fill_from_template">
              <div class="d-flex">
                <v-checkbox v-model="localOptions.by_change"
                            color="grey darken-2"
                            hide-details
                            label="Позмінний графік"
                            class="mr-4"
                />
                <v-select hide-details
                          v-if="localOptions.by_change"
                          v-model="localOptions.change_template"
                          :items="change_template"
                          label="Шаблон зміни"
                ></v-select>
              </div>
            </div>
            <div class="days-container mt-3" v-if="!localOptions.fill_from_template && !localOptions.by_change">
              <div class="mb-2 d-flex grey lighten-4 py-1" v-for="item in Object.keys(localOptions.day_times)"
                   :key="item">
                <v-col cols="2" class="py-1 d-flex align-center" :class="`color-${item}`" style="font-size: 1rem">
                  {{ localOptions.day_times[item].text }}
                </v-col>
                <v-col cols="5" class="py-1" style="display: flex">
                  <div style="flex: 0 0 50%">
                    <v-menu
                        :ref="`${item}_menu_start`"
                        v-model="localOptions.day_times[item].menu_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="localOptions.day_times[item].work_start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="localOptions.day_times[item].work_start"
                            class="mt-0 pt-0 mr-2 field-time"
                            hide-details
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="localOptions.day_times[item].menu_start"
                          v-model="localOptions.day_times[item].work_start"
                          full-width
                          @click:minute="saveTime(item, '_menu_start', 'work_start')"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                  <div style="flex: 0 0 50%">
                    <v-menu
                        :ref="`${item}_menu_end`"
                        v-model="localOptions.day_times[item].menu_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="localOptions.day_times[item].work_end"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="localOptions.day_times[item].work_end"
                            class="mt-0 pt-0 field-time"
                            hide-details
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="localOptions.day_times[item].menu_end"
                          v-model="localOptions.day_times[item].work_end"
                          full-width
                          @click:minute="saveTime(item, '_menu_end', 'work_end')"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col cols="5" class="py-1" style="display: flex">
                  <div style="flex: 0 0 50%">
                    <v-menu
                        :ref="`${item}_menu_break_start`"
                        v-model="localOptions.day_times[item].menu_break_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="localOptions.day_times[item].break_start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="localOptions.day_times[item].break_start"
                            class="mt-0 pt-0 mr-2 field-time"
                            hide-details
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            single-line
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="localOptions.day_times[item].menu_break_start"
                          v-model="localOptions.day_times[item].break_start"
                          full-width
                          @click:minute="saveTime(item, '_menu_break_start', 'break_start')"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                  <div style="flex: 0 0 50%">
                    <v-menu
                        :ref="`${item}_menu_break_end`"
                        v-model="localOptions.day_times[item].menu_break_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="localOptions.day_times[item].break_end"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="localOptions.day_times[item].break_end"
                            class="mt-0 pt-0 field-time"
                            hide-details
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="localOptions.day_times[item].menu_break_end"
                          v-model="localOptions.day_times[item].break_end"
                          full-width
                          @click:minute="saveTime(item, '_menu_break_end', 'break_end')"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                </v-col>
              </div>
            </div>
            <div class="mt-3 d-flex" v-if="!localOptions.fill_from_template">
              <v-checkbox v-model="localOptions.evening_days"
                          color="grey darken-2"
                          hide-details
                          label="Вечірні години"
                          class="mr-4"
              />
              <v-checkbox v-model="localOptions.night_days"
                          color="grey darken-2"
                          hide-details
                          label="Нічні години"
              />
            </div>
            <div class="mt-3 d-flex flex-wrap" v-if="!localOptions.fill_from_template">
              <v-col cols="12" sm="6" md="4"
                     v-if="(localOptions.template || 0) > 1 || localOptions.by_change"
              >
                <v-text-field
                    v-model.number="localOptions.hours_per_day"
                    hide-details
                    color="grey"
                    label="Годин в день"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                    ref="menu_work_start"
                    v-model="localOptions.menu_work_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="localOptions.general_times.work_start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localOptions.general_times.work_start"
                        label="Початок роб.годин"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="localOptions.menu_work_start"
                      v-model="localOptions.general_times.work_start"
                      full-width
                      @click:minute="$refs.menu_work_start.save(localOptions.general_times.work_start)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="localOptions.evening_days">
                <v-menu
                    ref="menu_evening_start"
                    v-model="localOptions.menu_evening_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="localOptions.general_times.evening_start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localOptions.general_times.evening_start"
                        label="Початок веч.годин"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="localOptions.menu_evening_start"
                      v-model="localOptions.general_times.evening_start"
                      full-width
                      @click:minute="$refs.menu_evening_start.save(localOptions.general_times.evening_start)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="localOptions.night_days">
                <v-menu
                    ref="menu_night_start"
                    v-model="localOptions.menu_night_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="localOptions.general_times.night_start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localOptions.general_times.night_start"
                        label="Початок ніч.годин"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="localOptions.menu_night_start"
                      v-model="localOptions.general_times.night_start"
                      full-width
                      @click:minute="$refs.menu_night_start.save(localOptions.general_times.night_start)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click.stop="fillWorkSchedule" class="mr-2">Заповнити</v-btn>
            <v-btn depressed @click.stop="fill_settings = false">Закрити</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row align="center" justify="space-between" class="pa-3 grey lighten-4 ma-0">
        <v-responsive width="580" max-width="580"
                      v-for="(month, idx_month) in items"
                      :key="`month-${idx_month}`"
                      class="px-3 py-1"
        >
          <v-card class="mr-4 mb-5" elevation="4">
            <div class="month-header">
              <div class="month-title">
                {{ month.name }}
              </div>
              <v-spacer></v-spacer>
              <div class="month-total">
                <v-chip small label class="mr-2" color="grey lighten-3">
                  {{ `Днів: ${month.total_days || 0} / Годин: ${month.total_hours || 0}` }}
                </v-chip>
              </div>
            </div>
            <div class="month-body">
              <div class="month-row"
                   v-for="(line, idx) in month.days"
                   :key="`month-${idx_month}-line-${idx}`"
              >
                <div class="month-col" :style="`height: ${style_height.month_col}px`">
                  <div class="col-top" style="height: 38px;
                border-bottom: .5px solid #e7e7e7; background-color: rgb(239,239,239)"
                  ></div>
                  <div class="col-bottom" style="font-size: .84rem" :style="`height: ${style_height.col_bottom}px`">
                    <div style="color: var(--v-grey-darken4)"
                         :style="`height: ${style_height.work}px; line-height: ${style_height.work}px`"
                    >рб
                    </div>
                    <div
                        style="color: var(--v-primary-base)"
                        :style="`height: ${style_height.evening}px; line-height: ${style_height.evening}px`"
                        v-if="fillOptions.evening_days"
                    >
                      вч
                    </div>
                    <div
                        style="color: var(--v-error-darken1)"
                        :style="`height: ${style_height.night}px; line-height: ${style_height.night}px`"
                        v-if="fillOptions.night_days"
                    >
                      нч
                    </div>
                  </div>
                </div>
                <div class="month-col" :style="`height: ${style_height.month_col}px`"
                     v-for="(day, idx_day) in line"
                     :key="`month-${idx_month}-line-${idx}-day-${idx_day}`"
                >
                  <div class="col-top" :class="day.class"
                       :style="day.day ? 'border-bottom: .5px solid #e7e7e7;' : ''"
                  >
                    <div
                        style="font-size: .7rem; font-weight: 500; padding-top: 2px" v-if="day.day_of_week"
                    >
                      {{ day.day_of_week ? dayName(day.day_of_week) : '' }}
                    </div>
                    <div style="font-size: .72rem; padding-bottom: 2px" v-if="day.day_of_week">{{ day.day }}</div>
                  </div>
                  <div class="col-bottom" :class="day.class" :style="`height: ${style_height.col_bottom}px`">
                    <div :style="`height: ${style_height.work}px; line-height: ${style_height.work}px`">
                      <input type="number" v-integer v-model.number="day.hours"
                             :maxlength="2" :min="0" :max="24"
                             v-if="day.day_of_week"
                             @input="reduceTotal($event, day)">
                    </div>
                    <div :style="`height: ${style_height.evening}px; line-height: ${style_height.evening}px`"
                         v-if="fillOptions.evening_days"
                    >
                      <input type="number" v-integer v-model.number="day.evening_hours"
                             maxlength="2" min="0" max="20"
                             v-if="day.day_of_week"
                             @input="reduceTotal($event, day)">
                    </div>
                    <div :style="`height: ${style_height.night}px; line-height: ${style_height.night}px`"
                         v-if="fillOptions.night_days"
                    >
                      <input type="text" v-integer v-model.number="day.night_hours"
                             maxlength="2" min="0" max="20"
                             v-if="day.day_of_week"
                             @input="reduceTotal($event, day)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-responsive>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_WORK_SCHEDULE_LIST_ELEMENTS,
  UPDATE_WORK_SCHEDULE_LIST_ELEMENTS,
  REMOVE_WORK_SCHEDULE_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {endOfMonth} from "@/utils/icons";
import calendarAPI from "@/utils/axios/accounting/calendar";
import workScheduleAPI from "@/utils/axios/accounting/work_shcedule"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'work_schedule_accounting_modal_delete'

const fillOptionsDefault = () => {
  return {
    fill_from_template: true,
    custom: false,
    by_change: false,
    change_date_start: null,
    evening_days: false,
    night_days: false,
    work_days: 5,
    change_template: null,
    days_in_week: 40,
    day_times: {
      monday: {work_start: '09:00', work_end: '22:00', break_start: '13:00', break_end: '14:00'},
      tuesday: {work_start: '09:00', work_end: '22:00', break_start: '13:00', break_end: '14:00'},
      wednesday: {work_start: '09:00', work_end: '22:00', break_start: '13:00', break_end: '14:00'},
      thursday: {work_start: '09:00', work_end: '22:00', break_start: '13:00', break_end: '14:00'},
      friday: {work_start: '09:00', work_end: '22:00', break_start: '13:00', break_end: '14:00'},
      saturday: {work_start: '', work_end: '', break_start: ''},
      sunday: {work_start: '', work_end: '', break_start: ''},
    },
    general_times: {
      work_start: '09:00',
      evening_start: '20:00',
      night_start: '22:00'
    },
    template_value: null
  }
}

const dayNames = [
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Сб',
  'Нд'
]

export default {
  name: "HWP_Modal_Work_Schedule",
  mixins: [ModalComponentMixin],
  data() {
    return {
      short_name: this.item.short_name,
      full_name: this.item.full_name,
      code: this.item.code || '',
      year: this.item.year || null,
      items: [],
      schedule_items: [],
      fillOptions: null,
      change_template: [
        {text: 'День через день', value: 'one_after_one'},
        {text: 'День через два', value: 'one_after_two'},
        {text: 'День через три', value: 'one_after_three'},
      ],
      fillTemplates: [
        {
          text: 'П’ятиденка (40-ний роб.день)',
          setting: {
            days_in_week: 40,
            work_days: 5,
            evening_days: false,
            night_days: false,
            change_template: null,
            by_change: false,
            change_date_start: null,
            custom: false,
            day_times: {
              monday: {work_start: '08:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              tuesday: {work_start: '08:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              wednesday: {work_start: '08:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              thursday: {work_start: '08:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              friday: {work_start: '08:00', work_end: '16:00', break_start: '13:00', break_end: '14:00'},
              saturday: {work_start: '', work_end: '', break_start: '', break_end: ''},
              sunday: {work_start: '', work_end: '', break_start: '', break_end: ''},
            },
            general_times: {
              work_start: '08:00',
              evening_start: null,
              night_start: null
            }
          },
          value: 1,
        },
        {
          text: 'Шестиденка (40-ний роб.день)',
          setting: {
            days_in_week: 40,
            work_days: 6,
            evening_days: false,
            night_days: false,
            change_template: null,
            change_date_start: null,
            by_change: false,
            custom: false,
            day_times: {
              monday: {work_start: '09:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              tuesday: {work_start: '09:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              wednesday: {work_start: '09:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              thursday: {work_start: '09:00', work_end: '17:00', break_start: '13:00', break_end: '14:00'},
              friday: {work_start: '09:00', work_end: '16:00', break_start: '13:00', break_end: '14:00'},
              saturday: {work_start: '09:00', work_end: '16:00', break_start: '', break_end: '14:00'},
              sunday: {work_start: '', work_end: '', break_start: '', break_end: ''},
            },
            general_times: {
              work_start: '08:00',
              evening_start: null,
              night_start: null
            }
          },
          value: 2
        },
        {
          text: 'День через день',
          setting: {
            days_in_week: null,
            work_days: null,
            evening_days: false,
            night_days: false,
            change_template: 'one_after_one',
            change_date_start: null,
            by_change: false,
            custom: false,
            day_times: null,
            hours_per_day: 12,
            general_times: {
              work_start: '08:00',
              evening_start: null,
              night_start: null
            }
          },
          value: 3
        },
        {
          text: 'День через два',
          setting: {
            days_in_week: null,
            work_days: null,
            evening_days: false,
            night_days: false,
            change_template: 'one_after_two',
            change_date_start: null,
            by_change: false,
            custom: false,
            day_times: null,
            hours_per_day: 12,
            general_times: {
              work_start: '08:00',
              evening_start: null,
              night_start: null
            }
          },
          value: 4
        },
        {
          text: 'День через три',
          setting: {
            days_in_week: null,
            work_days: null,
            evening_days: false,
            night_days: false,
            change_template: 'one_after_three',
            change_date_start: null,
            by_change: false,
            custom: false,
            day_times: null,
            hours_per_day: 12,
            general_times: {
              work_start: '08:00',
              evening_start: null,
              night_start: null
            }
          },
          value: 5
        },
      ],
      localOptions: {
        fill_from_template: true,
        template: null,
        evening_days: false,
        night_days: false,
        menu_work_start: false,
        menu_evening_start: false,
        menu_night_start: false,
        hours_per_day: 12,
        day_times: {
          monday: {
            text: 'Пн',
            work_start: '09:00',
            work_end: '22:00',
            break_start: '13:00',
            break_end: '14:00',
            menu_start: false,
            menu_end: false,
            menu_break_start: false,
            menu_break_end: false
          },
          tuesday: {
            text: 'Вт',
            work_start: '09:00',
            work_end: '22:00',
            break_start: '13:00',
            break_end: '14:00',
            menu_start: false,
            menu_end: false,
            menu_break_start: false,
            menu_break_end: false
          },
          wednesday: {
            text: 'Ср',
            work_start: '09:00',
            work_end: '22:00',
            break_start: '13:00',
            break_end: '14:00',
            menu_start: false,
            menu_end: false,
            menu_break_start: false,
            menu_break_end: false
          },
          thursday: {
            text: 'Чт',
            work_start: '09:00',
            work_end: '22:00',
            break_start: '13:00',
            break_end: '14:00',
            menu_start: false,
            menu_end: false,
            menu_break_start: false,
            menu_break_end: false
          },
          friday: {
            text: 'Пт',
            work_start: '09:00',
            work_end: '22:00',
            break_start: '13:00',
            break_end: '14:00',
            menu_start: false,
            menu_end: false,
            menu_break_start: false,
            menu_break_end: false
          },
          saturday: {
            text: 'Сб',
            work_start: '',
            work_end: '',
            break_start: '',
            break_end: '',
            menu_start: false,
            menu_end: false,
            menu_break_start: false,
            menu_break_end: false
          },
          sunday: {
            text: 'Нд',
            work_start: '',
            work_end: '',
            break_start: '',
            break_end: '',
            menu_start: false,
            menu_end: false,
            menu_break_start: false,
            menu_break_end: false
          },
        },
        general_times: {
          work_start: '09:00',
          evening_start: '20:00',
          night_start: '22:00'
        },
        by_change: false,
        change_date_start: null,
        change_template: null,
      },
      style_height: {
        month_col: 68,
        col_bottom: 30,
        work: 30,
        evening: 0,
        night: 0
      },
      fill_settings: false,
      calendar_fill: false,
      calendar_items: []
    }
  },
  methods: {
    getStyleHeight() {
      const style_height = {
        month_col: 68,
        col_bottom: 30,
        work: 30,
        evening: 0,
        night: 0
      }

      if (this.fillOptions.evening_days && this.fillOptions.night_days) {
        style_height.month_col = 38 + 23 + 23 + 23
        style_height.col_bottom = 69
        style_height.work = 23
        style_height.evening = 23
        style_height.night = 23
      }

      if (this.fillOptions.evening_days && !this.fillOptions.night_days) {
        style_height.month_col = 38 + 26 + 26
        style_height.col_bottom = 52
        style_height.work = 26
        style_height.evening = 26
        style_height.night = 0
      }

      if (!this.fillOptions.evening_days && this.fillOptions.night_days) {
        style_height.month_col = 38 + 26 + 26
        style_height.col_bottom = 52
        style_height.work = 26
        style_height.evening = 26
        style_height.night = 0
      }

      this.style_height = style_height

    },
    getDayTimesFromLocal() {
      const day_times = {}
      Object.keys(this.localOptions.day_times).forEach(key => {
        const obj = this.localOptions.day_times[key]
        day_times[key] = {
          work_start: obj.work_start,
          work_end: obj.work_end,
          break_start: obj.break_start,
          break_end: obj.break_end
        }
      })
      return day_times
    },
    fillWorkSchedule() {
      this.fillOptions.fill_from_template = this.localOptions.fill_from_template
      this.fillOptions.template_value = this.localOptions.template === null ? null : this.localOptions.template + 1
      this.fillOptions.evening_days = this.localOptions.evening_days
      this.fillOptions.night_days = this.localOptions.night_days
      this.fillOptions.hours_per_day = this.localOptions.hours_per_day
      this.fillOptions.day_times = this.getDayTimesFromLocal()
      this.fillOptions.general_times = Object.assign({}, this.localOptions.general_times)
      this.fillOptions.by_change = this.localOptions.by_change
      this.fillOptions.change_date_start = this.localOptions.change_date_start
      this.fillOptions.change_template = this.localOptions.change_template

      this.getStyleHeight()


      if (this.fillOptions.fill_from_template) {
        if (this.fillOptions.template_value === 1) {
          this.fillFiveDaysWork()
        }
        if (this.fillOptions.template_value === 2) {
          this.fillSixDaysWork()
        }
        if (this.fillOptions.template_value === 3) {
          this.fillChangeOneByOne()
        }
        if (this.fillOptions.template_value === 4) {
          this.fillChangeOneByTwo()
        }
        if (this.fillOptions.template_value === 5) {
          this.fillChangeOneByThree()
        }
      } else {
        if (this.fillOptions.by_change) {
          if (this.fillOptions.change_template === 'one_after_one') {
            this.fillChangeOneByOne()
          }
          if (this.fillOptions.change_template === 'one_after_two') {
            this.fillChangeOneByTwo()
          }
          if (this.fillOptions.change_template === 'one_after_three') {
            this.fillChangeOneByThree()
          }
        } else {
          this.fillCustomByDays()
        }
      }
      this.fill_settings = false
      this.getScheduleTotal()
    },
    convertToTime(payload) {
      if (!payload) return 0
      return +payload.split(":")[0] * 60 + (+payload.split(":")[1])
    },
    getDaysHour(hours, start_change = null, work_day_start = null, work_day_end = null) {
      const list = []
      const result = {hours: hours, evening: 0, night: 0}
      const change_start = !start_change ? this.fillOptions.general_times.work_start : start_change
      let work_time_end, change_time_end, work_time = null
      let change_time_start = this.convertToTime(change_start)

      change_time_end = change_time_start + (hours * 60)
      change_time_end = change_time_end >= 1440 ? 1440 : change_time_end

      const work_time_start = !work_day_start ? this.fillOptions.general_times.work_start || '06:00' : work_day_start
      work_time = this.convertToTime(work_time_start)

      if (!work_day_end) {
        work_time_end = work_time + (hours * 60)
      } else {
        work_time_end = this.convertToTime(work_day_end)
      }
      work_time_end = work_time_end >= 1440 ? 1440 : work_time_end

      const evening_time_start = this.fillOptions.general_times.evening_start || '18:00'
      const evening_time = this.convertToTime(evening_time_start)

      const night_time_start = this.fillOptions.general_times.night_start || '22:00'
      const night_time = this.convertToTime(night_time_start)

      list.push({from: 1, to: work_time - 1, type: 'night'})
      list.push({from: evening_time, to: night_time - 1, type: 'evening'})
      if (night_time >= work_time) {
        list.push({from: night_time, to: 1440, type: 'night'})
      }

      const time_start = start_change ? change_time_start : work_time
      const time_end = start_change ? change_time_end : work_time_end

      list.forEach(i => {
        if ((time_start >= i.from && time_start <= i.to) && (time_end >= i.from && time_end <= i.to)) {
          result[i.type] += Math.round((time_end - time_start + 1) / 60)
        }
        if ((time_start >= i.from && time_start <= i.to) && !(time_end >= i.from && time_end <= i.to)) {
          if (i.to >= time_end) {
            result[i.type] += Math.round((time_end - time_start + 1) / 60)
          } else {
            result[i.type] += Math.round((i.to - time_start + 1) / 60)
          }
        }
        if (!(time_start >= i.from && time_start <= i.to) && (time_end >= i.from && time_end <= i.to)) {
          result[i.type] += Math.round((time_end - i.from + 1) / 60)
        }
        if (!(time_start >= i.from && time_start <= i.to) && !(time_end >= i.from && time_end <= i.to)) {
          if (time_start < i.from && time_end >= i.to) {
            result[i.type] += Math.round((i.to - i.from + 1) / 60)
          }
        }
      })

      return {
        hours: result.hours,
        evening: this.fillOptions.evening_days ? result.evening : 0,
        night: this.fillOptions.night_days ? result.night : 0
      }
    },
    saveTime(day, ref_end, attr_name) {
      this.$refs[`${day}${ref_end}`][0].save(this.localOptions.day_times[day][attr_name])
    },
    getItemHour(month, day) {
      const item = this.items[month].days[day]
      if (item) {
        return item.hours
      }
      return 0
    },
    addHour(time, add) {
      if (!time) return time
      const hour = +time.split(":")[0] + add
      return `${hour < 10 ? '0' + hour : hour}:${time.split(":")[1]}`
    },
    monthName() {
      return [
        'Січень',
        'Лютий',
        'Березень',
        'Квітень',
        'Травень',
        'Червень',
        'Липень',
        'Серпень',
        'Вересень',
        'Жовтень',
        'Листопад',
        'Грудень',
      ]
    },
    dayName(day_of_week) {
      return dayNames[day_of_week - 1]
    },
    getFillArray() {
      return [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    },
    buildItems() {
      const year = this.year
      const month_count = 12
      const local_items = []
      const month_names = this.monthName()
      const items_per_line = 16

      for (let month = 1; month < month_count + 1; month++) {
        const day_in_month = endOfMonth(new Date(year, month - 1, 1), 'date').getDate()
        const month_object = {
          name: month_names[month - 1],
          month: month,
          year: year,
          days: [],
          total_days: 0,
          total_hours: 0,
          total_evening_hours: 0,
          total_night_hours: 0
        }
        let portion = 0
        let count = 0

        for (let day = 1; day < day_in_month + 1; day++) {
          const day_of_week = new Date(year, month - 1, day).getDay()

          let day_of_week_ukr = 0
          if (day === 1) {
            portion = 1
            month_object.days.push(this.getFillArray())
          }

          if (day_of_week === 0) {
            day_of_week_ukr = 7
          } else {
            day_of_week_ukr = day_of_week
          }

          const date = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`

          const calendar_item = this.calendar_items.find(i => i.date === date)

          let day_type = ''
          let class_type = ''

          count += 1

          if (day_of_week_ukr <= 5) {
            day_type = 'work'
            class_type = 'work'
          } else if (day_of_week_ukr === 6) {
            day_type = 'opening'
            class_type = 'opening-1'
          } else if (day_of_week_ukr === 7) {
            day_type = 'opening'
            class_type = 'opening-2'
          }

          if (calendar_item) {
            day_type = calendar_item.day_type

            if (day_type === 'work') {
              class_type = 'work'
            } else if (day_type === 'preopening') {
              class_type = 'preopening-1'
            } else if (day_type === 'holiday') {
              class_type = 'holiday-1'
            }
          }

          const schedule_item = this.schedule_items.find(i => i.date === date)

          month_object.days[portion - 1][count - 1] = {
            day: day,
            month: month,
            year: year,
            date: date,
            type: day_type,
            day_of_week: day_of_week_ukr,
            class: class_type,
            hours: schedule_item ? schedule_item.hours : null,
            evening_hours: schedule_item ? schedule_item.evening : null,
            night_hours: schedule_item ? schedule_item.night : null
          }

          if (count === items_per_line) {
            portion += 1
            month_object.days.push(this.getFillArray())
            count = 0
          }
        }
        local_items.push(month_object)
      }

      this.items = local_items
    },
    getScheduleTotal() {
      Object.keys(this.items).forEach(key => {
        const month = this.items[key]
        let days = 0
        let hours = 0

        month.days.forEach(line => {
          line.forEach(i => {
            if ((i.hours || 0) > 0) {
              days += 1
              hours += i.hours
            }
          })
        })

        month.total_days = days
        month.total_hours = hours
      })
    },
    reduceTotal(e, payload) {
      let days = 0
      let hours = 0
      this.items[payload.month - 1].days.forEach(line => {
        line.forEach(i => {
          if ((i.hours || 0) > 0) {
            days += 1
            hours += i.hours
          }
        })
      })
      this.items[payload.month - 1].total_days = days
      this.items[payload.month - 1].total_hours = hours
    },
    fillFiveDaysWork() {
      this.items.forEach(month => {
        month.days.forEach(line => {
          line.forEach(day => {
            day.hours = null
            day.evening_hours = null
            day.night_hours = null

            if (day.type === 'work') {
              day.hours = 8
            }
            if (day.type === 'preopening') {
              day.hours = 7
            }
          })
        })
      })
    },
    fillSixDaysWork() {
      const hours_per_full_day = Math.ceil(40 / 6)
      const hours_per_non_full_day = 40 - hours_per_full_day * 5

      this.items.forEach(month => {
        month.days.forEach(line => {
          line.forEach(day => {
            day.hours = null
            day.evening_hours = null
            day.night_hours = null

            if (day.type === 'work') {
              day.hours = hours_per_full_day
            }
            if (day.type === 'preopening') {
              if (day.day_of_week >= 6) {
                day.hours = hours_per_non_full_day - 1
              } else {
                day.hours = hours_per_full_day - 1
              }
            }
            if (day.type === 'opening' && day.day_of_week === 6) {
              day.hours = hours_per_non_full_day
            }
          })
        })
      })
    },
    fillCustomByDays() {
      const times = {}
      let total_hours = 0

      Object.entries(this.fillOptions.day_times).forEach((i, idx) => {
        const hours = (this.convertToTime(i[1].work_end) - this.convertToTime(i[1].work_start)) / 60
        const break_start = this.convertToTime(i[1].break_start)
        const break_end = this.convertToTime(i[1].break_end)
        let break_hours = break_end && break_end ? (break_end - break_start) / 60 : 0

        if (i[1].work_start) {
          const day_hours = this.getDaysHour(0, null, i[1].work_start, i[1].work_end)

          times[idx] = {
            work_start: i[1].work_start,
            work_end: i[1].work_end,
            break_start: i[1].break_start,
            break_end: i[1].break_end,
            hours: hours - break_hours,
            evening: day_hours.evening,
            night: day_hours.night
          }
          total_hours += hours - break_hours
        }
      })
      this.items.forEach(month => {
        month.days.forEach(line => {
          line.forEach(day => {
            const item = times[day.day_of_week - 1]

            if (day.type === 'work') {
              if (item) {
                day.hours = item.hours || null
                day.evening_hours = item.evening || null
                day.night_hours = item.night || null
              } else {
                day.hours = null
                day.evening_hours = null
                day.night_hours = null
              }
            }

            if (day.type === 'preopening') {
              if (item) {
                if (total_hours === 40) {
                  const new_end = this.addHour(item.work_end, -1)
                  const days = this.getDaysHour(0, null, item.work_start, new_end)

                  day.hours = days.hours || null
                  day.evening_hours = days.evening || null
                  day.night_hours = days.night || null
                } else {
                  day.hours = item.hours || null
                  day.evening_hours = item.evening || null
                  day.night_hours = item.night || null
                }
              } else {
                day.hours = null
                day.evening_hours = null
                day.night_hours = null
              }
            }

            if (day.type === 'opening') {
              if (item) {
                day.hours = item.hours || null
                day.evening_hours = item.evening || null
                day.night_hours = item.night || null
              } else {
                day.hours = null
                day.evening_hours = null
                day.night_hours = null
              }
            }

            if (day.type === 'holiday') {
              day.hours = null
              day.evening_hours = null
              day.night_hours = null
            }

          })
        })
      })
    },
    fillChangeOneByOne() {
      const days_hours = this.getDaysHour(this.localOptions.hours_per_day || 24, '00:01')
      const change_date_start = new Date(this.fillOptions.change_date_start || '2011-01-01')
      const change_list = [{
        hours: days_hours.hours,
        evening_hours: days_hours.evening,
        night_hours: days_hours.night
      }, {}]

      this.items.forEach(month => {
        month.days.forEach(line => {
          line.forEach(day => {
            if (day.date) {
              const date = new Date(day.date)
              let changeIndex = ((date.getTime() - change_date_start.getTime()) / 1000 / 86400) % change_list.length
              changeIndex = Math.abs(changeIndex)

              if (change_list[changeIndex].hours) {
                day.hours = change_list[changeIndex].hours
                day.evening_hours = change_list[changeIndex].evening_hours || null
                day.night_hours = change_list[changeIndex].night_hours || null
              } else {
                day.hours = null
                day.evening_hours = null
                day.night_hours = null
              }
            }
          })
        })
      })
    },
    fillChangeOneByTwo() {
      const days_hours = this.getDaysHour(this.localOptions.hours_per_day || 24, '00:01')
      const change_date_start = new Date(this.fillOptions.change_date_start || '2011-01-01')
      const change_list = [{
        hours: days_hours.hours,
        evening_hours: days_hours.evening,
        night_hours: days_hours.night
      }, {}, {}]

      this.items.forEach(month => {
        month.days.forEach(line => {
          line.forEach(day => {
            if (day.date) {
              const date = new Date(day.date)
              let changeIndex = ((date.getTime() - change_date_start.getTime()) / 1000 / 86400) % change_list.length
              changeIndex = Math.abs(changeIndex)

              if (change_list[changeIndex].hours) {
                day.hours = change_list[changeIndex].hours
                day.evening_hours = change_list[changeIndex].evening_hours || null
                day.night_hours = change_list[changeIndex].night_hours || null
              } else {
                day.hours = null
                day.evening_hours = null
                day.night_hours = null
              }
            }
          })
        })
      })
    },
    fillChangeOneByThree() {
      const days_hours = this.getDaysHour(this.localOptions.hours_per_day || 24, '00:01')
      const change_date_start = new Date(this.fillOptions.change_date_start || '2011-01-01')
      const change_list = [{
        hours: days_hours.hours,
        evening_hours: days_hours.evening,
        night_hours: days_hours.night
      }, {}, {}, {}]

      this.items.forEach(month => {
        month.days.forEach(line => {
          line.forEach(day => {
            if (day.date) {
              const date = new Date(day.date)
              let changeIndex = ((date.getTime() - change_date_start.getTime()) / 1000 / 86400) % change_list.length
              changeIndex = Math.abs(changeIndex)

              if (change_list[changeIndex].hours) {
                day.hours = change_list[changeIndex].hours
                day.evening_hours = change_list[changeIndex].evening_hours || null
                day.night_hours = change_list[changeIndex].night_hours || null
              } else {
                day.hours = null
                day.evening_hours = null
                day.night_hours = null
              }
            }
          })
        })
      })
    },
    fetchData() {
      calendarAPI.get(this.year)
          .then(response => response.data)
          .then(data => {
            this.calendar_fill = data.is_new
            this.calendar_items = data.items

            if (!this.isNew) {
              workScheduleAPI.get_rows(this.itemId)
                .then(response => response.data)
                .then(data => {
                  this.schedule_items = data
                  this.buildItems()
                  this.getScheduleTotal()
                })
                .catch(err => {
                  const error = err.response.data.detail;
                  this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                })
            } else {
              this.buildItems()
              this.getScheduleTotal()
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    changeYear(payload) {
      this.year += payload || 0
      this.fetchData()
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.short_name = this.item.short_name
      this.full_name = this.item.full_name
      this.code = this.item.code
      this.year = this.item.year || null
      this.fillOptions = this.item.fill_options || fillOptionsDefault()

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення графіку роботи: ${this.short_name} за ${this.year}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    getPayloadRows() {
      const payload = []
      this.items.forEach(month => {
        month.days.forEach(line => {
          line.forEach(day => {
            if (day.hours) {
              payload.push(
                  {
                    hours: day.hours,
                    evening: day.evening_hours,
                    night: day.night_hours,
                    date: day.date
                  }
              )
            }
          })
        })
      })
      return payload
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Найменування та рік мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const rows = this.getPayloadRows()

      const payload = {
        short_name: this.short_name,
        full_name: this.full_name || this.short_name,
        code: this.code,
        year: this.year,
        fill_options: this.fillOptions || fillOptionsDefault(),
        rows: rows
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_WORK_SCHEDULE_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_WORK_SCHEDULE_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: false,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.short_name = payload.short_name
              this.full_name = payload.full_name
              this.code = payload.code
              this.year = payload.year || +(new Date().getFullYear())
              if (payload.fill_options) {
                this.fillOptions = Object.assign({}, payload.fill_options)
              } else {
                this.fillOptions = Object.assign({}, fillOptionsDefault())
              }

              this.getStyleHeight()
              this.fetchData()
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_WORK_SCHEDULE_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.field-time {
  :deep(.v-input__control) {
    width: 90% !important;

    .v-input__slot:before {
      //display: none !important;
    }

    .v-input__slot:after {
      display: none !important;
    }

    .v-input__slot {
      .v-text-field__slot {
        input {
          padding-top: 3px !important;
          padding-bottom: 3px !important;
        }
      }
    }

  }
}

.month-header {
  display: flex;
  padding: 10px 10px;
  color: #2d2d2d;
  border-bottom: .5px solid #e7e7e7;

  .month-title {
    font-size: 1.2rem;
  }
}

.month-row {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: .5px solid #e7e7e7;
  width: 100%;

  .month-col {
    flex: 0 0 5.8823%;
    border-right: .5px solid #e7e7e7;
    text-align: center;

    .col-top {
      font-size: .8rem;
      background-color: rgba(243, 243, 243, 0.59);
      line-height: 1rem;
    }

    .col-bottom {
      font-size: .8rem;

      input {
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        padding: 4px;
        text-align: center;
        font-size: .78rem;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }

    &:nth-child(17) {
      border-right: none !important;
    }
  }
}

.opening-1 {
  color: #c46d37;
}

.opening-2 {
  color: #d32f2f;
}

.preopening-1 {
  color: var(--v-primary-base);
}

.holiday-1 {
  color: #009688;
}

.color-saturday {
  color: var(--v-secondary-base);
}

.color-sunday {
  color: var(--v-error-base);
}

.color-monday {
  color: var(--v-primary-base);
}

.color-tuesday {
  color: var(--v-primary-base);
}

.color-wednesday {
  color: var(--v-primary-base);
}

.color-thursday {
  color: var(--v-primary-base);
}

.color-friday {
  color: var(--v-primary-base);
}
</style>