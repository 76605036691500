import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/calendar';

export default {
    get(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {year: payload}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
};